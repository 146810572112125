import { render, staticRenderFns } from "./ForwardRequestList.vue?vue&type=template&id=221d4075&scoped=true&"
import script from "./ForwardRequestList.vue?vue&type=script&lang=js&"
export * from "./ForwardRequestList.vue?vue&type=script&lang=js&"
import style0 from "./ForwardRequestList.vue?vue&type=style&index=0&id=221d4075&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221d4075",
  null
  
)

export default component.exports